export enum Language {
  DE = 'de',
  FR = 'fr',
  IT = 'it',
  EN = 'en',
}

export const DEFAULT_LANGUAGE = Language.DE;

export const isValidLanguage = (language: string): boolean => {
  return Object.values(Language).includes(language as Language);
};

export const fromLanguageStringToEnum = (languageAsString: string): Language => {
  switch (languageAsString) {
    case Language.DE: {
      return Language.DE;
    }
    case Language.EN: {
      return Language.EN;
    }
    case Language.FR: {
      return Language.FR;
    }
    case Language.IT: {
      return Language.IT;
    }
    default: {
      console.warn(`Tried to parse unknown language ${languageAsString}`);
      return DEFAULT_LANGUAGE;
    }
  }
};
