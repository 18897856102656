import { LocalStorageKey } from '../enums/local-storage-key';
import {
  DEFAULT_LANGUAGE,
  fromLanguageStringToEnum,
  isValidLanguage,
  Language,
} from '../enums/language';

export const getInitialLanguage = (): string => {
  try {
    // first check if something is stored in local storage
    const localStorageLanguage: string | null = localStorage.getItem(LocalStorageKey.UI_LANGUAGE);
    if (localStorageLanguage) {
      if (isValidLanguage(localStorageLanguage)) {
        return localStorageLanguage;
      } else {
        console.warn(
          `There was an unsupported language (${localStorageLanguage}) in local storage`
        );
      }
    }
    // if nothing useful is found, check for navigator languages
    if (!navigator || !navigator.languages) {
      return DEFAULT_LANGUAGE;
    }
    return (
      navigator.languages
        // e.g. from ['de-CH', 'de', 'de-DE', 'en', 'en-GB', 'es-US'] to ['de', 'de', 'de', 'en', 'en', 'es']
        .map((localizedLanguage) => localizedLanguage.split('-')[0])
        // take first language that is supported from application. Otherwise, return default
        .find((language) => isValidLanguage(language)) || DEFAULT_LANGUAGE
    );
  } catch (e) {
    console.error(e);
    return DEFAULT_LANGUAGE;
  }
};

export const changeLanguage = (
  languageAsString: string,
  baseHref: string,
  replace: boolean = false,
  location: Location = window.location // purely to support testing, therefore I don't like it but haven't found other solution
): void => {
  const language: Language = fromLanguageStringToEnum(languageAsString);
  localStorage.setItem(LocalStorageKey.UI_LANGUAGE, language);
  const index = baseHref.indexOf('/', 1);
  if (index !== -1) {
    baseHref = baseHref.substring(0, index + 1);
  }
  let currentPath: string = computeCurrentPath(location);
  if (currentPath.replaceAll('/', '') === baseHref.replaceAll('/', '')) {
    // the path is the same as the baseHref (so the defined --base-href is expected), we add a slash so that the URL ends with one
    currentPath = '/';
  } else if (getLanguageStringFromPath(currentPath) !== '') {
    // remove everything up to the current language sub path
    currentPath = currentPath.slice(baseHref.length + 2);
  }
  // the new path should preserve path elements like query params, hashes or other route information.
  const newPath: string = `${baseHref}${language}${currentPath}`;
  // required for initial routing. We don't want the user being able to go back to language-router mini app
  if (replace) {
    location.replace(newPath);
  } else {
    location.href = newPath;
  }
};

const getLanguageStringFromPath = (path: string): string => {
  // Split the path into segments
  const segments = path.split('/').filter((segment) => segment.length > 0);
  for (const segment of segments) {
    if (isValidLanguage(segment)) {
      return segment;
    }
  }
  return '';
};

/**
 * returns the current path, starting with a slash "/", e.g. "/de/transactions" for built system or "/transaction" for local development.
 */
export const computeCurrentPath = (location: Location = window.location): string => {
  const protocol: string = location.protocol;
  const host: string = location.host;
  const href: string = location.href;
  return href.replace(`${protocol}//${host}`, '');
};

export const getCurrentLanguageFromPath = (): Language | undefined => {
  const currentPath: string = computeCurrentPath();
  // if current path contains language, language is one and two characters after the baseHref (e.g. /static/de/transactions)
  const pathLang = getLanguageStringFromPath(currentPath);
  if (pathLang !== '') {
    return fromLanguageStringToEnum(pathLang);
  }
  // this is the case when developing locally
  return undefined;
};
